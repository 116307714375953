import React, { useEffect } from "react"

import { Card, CardTitle, CardBody } from "reactstrap"

import FormMultiSelect from "./FormMultiSelect"
import FormTextArea from "./formTextArea"
import FormSingleSelect from "./FormSingleSelect"
import FormSelectBox from "./formSelectBox"
import FormOneLiner from "./FormOneLiner"
import FormDate from "./FormDate"
import FormAge from "./FormAge"
import FormBirthday from "./FormBirthday"
import ImageUploader from "./image_uploader"

import { InterviewItem, InputInterviewAnswer } from "./types"
import FormPrescriptionMonth from "./FormPrescriptionMonth/index"
import FormAdrress from "./FormAddress"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  handleChange: (id, value) => void
  imageUploadPath: string
  setErrorMessage: (errorMessage: string) => void
  inputInterviewAnswers: Array<InputInterviewAnswer>
  ageValue?: number
  customerId?: string | null
}

const FormInputElement = React.forwardRef(function FormInputElement(
  props: Props,
  ref: React.LegacyRef<HTMLDivElement>
) {
  const initialValue = (id: string) => {
    const result = props.inputInterviewAnswers.find((info) => info.id === id)

    if (!result) return

    return result.value
  }
  let viewFlg = false
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !viewFlg) {
        window.dataLayer.push({
          event: "item_view",
          itemTitle: props.data.title,
        })
        viewFlg = true
      }
    })
    if (ref === null) return
    // @ts-ignore
    observer.observe(ref.current)
  }, [])
  return (
    <div ref={ref}>
      <FormInputElementItem
        data={props.data}
        handleChange={props.handleChange}
        initialValue={initialValue(props.data.id)}
        ageValue={props.ageValue}
        imageUploadPath={props.imageUploadPath}
        setErrorMessage={props.setErrorMessage}
        customerId={props.customerId}
      />
    </div>
  )
})

type ItemProps = {
  data: InterviewItem
  handleChange: (id, value) => void
  initialValue?: string | number
  setErrorMessage: (errorMessage: string) => void
  ageValue?: number
  imageUploadPath: string
  customerId?: string | null
}

const FormInputElementItem: React.FC<ItemProps> = (props) => {
  return (
    <Card className="mb-4 py-3">
      <CardBody>
        <CardTitle className="WebInterview__Label">
          {props.data.title}{" "}
          {props.data.required && <span className="text-red">必須</span>}
        </CardTitle>
        {!!props.data.note && (
          <pre className="mb-3 WebInterview__Note">{props.data.note}</pre>
        )}
        {(() => {
          switch (props.data.question_type) {
            case "select_box":
              return (
                <FormSelectBox
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue}
                />
              )
            case "single_select":
              return (
                <FormSingleSelect
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue}
                />
              )
            case "multi_select":
              return (
                <FormMultiSelect
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue}
                />
              )
            case "text":
              return (
                <FormTextArea
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue}
                />
              )
            case "one_liner":
              return (
                <FormOneLiner
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue}
                />
              )
            case "date_year_month":
              return (
                <FormDate
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue?.toString()}
                />
              )
            case "image":
              return (
                <ImageUploader
                  title={props.data.title}
                  imageUploadPath={props.imageUploadPath}
                  setErrorMessage={props.setErrorMessage}
                />
              )
            case "birthday":
              return (
                <FormBirthday
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue?.toString()}
                />
              )
            case "age":
              return (
                <FormAge
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue}
                  value={props.ageValue}
                />
              )
            case "address":
              return (
                <FormAdrress
                  data={props.data}
                  handleChange={props.handleChange}
                />
              )
            case "prescription_month":
              return (
                <FormPrescriptionMonth
                  data={props.data}
                  handleChange={props.handleChange}
                  initialValue={props.initialValue}
                  customerId={props.customerId}
                />
              )
            default:
              return null
          }
        })()}
      </CardBody>
    </Card>
  )
}

export default FormInputElement
