import React, { useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { InterviewItem, InputInterviewAnswer } from "../types"
import IndexPresentation from "../indexPresentation"

type Props = {} & RouteComponentProps<{ customerId: string; datetime: string }>

const WebInterviewPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props

  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [isHideAlert, setIsHideAlert] = useState<boolean>(true)
  let [interviewItems, setInterviewItems] = useState<Array<InterviewItem>>([])
  let [interviewChildItems, setInterviewChildItems] = useState<
    Array<InterviewItem>
  >([])
  let [inputInterviewAnswers, setInputInterviewAnswers] = useState<
    Array<InputInterviewAnswer>
  >([])

  const onSubmit = () => {
    ;(async () => {
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const image_ids = Array.from(
        document.querySelectorAll("input.image-id")
      ).map((v) => v.getAttribute("value"))
      const response = await fetch(`/api/followup_images`, {
        method: "POST",
        body: JSON.stringify({
          customer_id: params.customerId,
          image_ids: image_ids,
        }),
        headers,
      })

      if (response.status === 201 || response.status === 200) {
        window.location.href = `/thanks_followup`
        return
      }
      const json = await response.json()
      handleErrorMessage(json.errors)
    })()
  }

  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setIsHideAlert(false)

    new Promise((resolve) => {
      setTimeout(() => {
        setIsHideAlert(true)
        resolve(null)
      }, 5000)
    }).then(() => {
      return setTimeout(() => {
        // isHideAlertがtrueのままだとtransitionが働いてfadeInしてしまうのでリセット
        // ↑ に伴ってerrorMessageをnullにすることで非表示にする
        // fadeOutに1秒かかるので1秒待ってからリセット
        setErrorMessage(null)
        setIsHideAlert(false)
      }, 1000)
    })
  }
  const TopDescription = <></>

  return (
    <>
      <IndexPresentation
        errorMessage={errorMessage}
        buttonText="写真を投稿する"
        interviewItems={interviewItems}
        interviewChildItems={interviewChildItems}
        inputInterviewAnswers={inputInterviewAnswers}
        TopDescription={TopDescription}
        imageUploadPath={`/api/followup_image_items`}
        isShowHeadImageInput={true}
        isHideAlert={isHideAlert}
        setErrorMessage={handleErrorMessage}
        setInputInterviewAnswers={setInputInterviewAnswers}
        setInterviewItems={setInterviewItems}
        onSubmit={onSubmit}
        canAnswer={true}
      ></IndexPresentation>
    </>
  )
}

export default withRouter(WebInterviewPage)
