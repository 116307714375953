import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"

import ReservationForm from "./ReservationForm"
import { Customer } from "../../types"
import ReservationConfirm from "../../layout/ReservationConfirm"

type Props = {} & RouteComponentProps<{ customerId: string; datetime: string }>

const NewCustomerMedicalExaminationPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [isFirstExamination, setIsFirstExamination] = useState<boolean>(false)

  // FIXME: 本来 endAt は 前のページから props で渡したい
  const [endAt, setEndAt] = useState<Date | null>(null)

  const startAt = moment(params.datetime, "YYYYMMDDHHmm")

  const backSelectSchedule = () => {
    if (
      window.location.pathname.split("/")[1] ===
      "customer_schedule_first_medical_examinations"
    ) {
      history.push(
        `/customer_schedule_first_medical_examinations/${params.customerId}`
      )
    } else {
      history.push(
        `/customer_schedule_medical_examinations/${params.customerId}${window.location.search}`
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      const is_first_examination = await fetch(
        `/api/is_first_examination/${params.customerId}`
      )
      const res = await is_first_examination.json()

      setIsFirstExamination(res.data)
      setCustomer(customer)
    })()
  }, [params])

  useEffect(() => {
    ;(async () => {
      const searchParams = new URLSearchParams({
        type: "medical_examination",
        target_date: startAt.toISOString(),
        customer_id: params.customerId,
      })
      const res = await fetch(
        `/api/schedulable_times_one_days?${searchParams.toString()}`
      )
      const schedule = await res.json()
      const selectedTime = schedule.schedulable_times.times.find((time) => {
        return new Date(time.start_at).toISOString() === startAt.toISOString()
      })
      if (selectedTime) {
        setEndAt(new Date(selectedTime.end_at))
      }
    })()
  }, [])

  if (customer === null) {
    return null
  }

  return (
    <>
      <ReservationConfirm
        displayMessage={`${customer.name}様 診療予約`}
        startAt={startAt.toDate()}
        endAt={endAt}
        backSelectSchedule={backSelectSchedule}
        ReservationForm={
          <ReservationForm
            customerId={params.customerId}
            startAt={startAt.toDate()}
            isFirstExamination={isFirstExamination}
          />
        }
      />
    </>
  )
}

export default withRouter(NewCustomerMedicalExaminationPage)
