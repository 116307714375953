import React, { useState, useEffect, useMemo } from "react"

import { withRouter, RouteComponentProps, useLocation } from "react-router-dom"
import { InterviewItem, InputInterviewAnswer } from "../types"
import IndexPresentation from "../indexPresentation"

type Props = {} & RouteComponentProps<{ customerId: string; datetime: string }>

type WithdrawInterviewCancelPrescription = {
  action: "cancelPrescription"
  medicalExaminationId: string
}

const WithdrawalInterviewPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props

  let [errorMessage, setErrorMessage] = useState<string | null>(null)
  let [isHideAlert, setIsHideAlert] = useState<boolean>(true)
  let [interviewItems, setInterviewItems] = useState<Array<InterviewItem>>([])
  let [interviewChildItems, setInterviewChildItems] = useState<
    Array<InterviewItem>
  >([])
  let [inputInterviewAnswers, setInputInterviewAnswers] = useState<
    Array<InputInterviewAnswer>
  >([])
  let [canAnswer, setCanAnswer] = useState<boolean>(true)

  const useWithdrawInterviewSearchParams = (): WithdrawInterviewCancelPrescription | null => {
    const { search } = useLocation()
    const query = useMemo(() => new URLSearchParams(search), [search])
    const action = query.get("action")
    const medicalExaminationId = query.get("medicalExaminationId")

    if (action === "cancelPrescription" && medicalExaminationId) {
      return {
        action: action,
        medicalExaminationId: medicalExaminationId,
      }
    } else {
      return null
    }
  }

  const withdrawInterviewSearchParams = useWithdrawInterviewSearchParams()

  useEffect(() => {
    ;(async () => {
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-Requested-With", "XMLHttpRequest")

      // アンケート項目の取得
      await fetch(`/api/web_interview_items/withdrawal_interview`, {
        method: "GET",
        headers,
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              setInterviewItems(json.withdrawal_interview_items)
              setInterviewChildItems(json.withdrawal_interview_child_items)
            })
          } else if (response.status === 404) {
            setCanAnswer(false)
            alert("URLに誤りがあります。ご確認のうえ再度アクセスお願いします。")
          } else {
            setCanAnswer(false)
            alert(
              "予期せぬエラーが発生しました。お手数ですがサポートまでお問い合わせください。"
            )
          }
        })
        .catch((error) => {
          console.error("アンケートアイテムの取得に失敗しました: ", error)
          setCanAnswer(false)
          alert("通信状態をお確かめの上、再度アクセスお願いします。")
        })
    })()
  }, [])

  const onSubmit = () => {
    ;(async () => {
      // 複数選択のvalueがArrayになってるのでjoinする
      // Arrayの方が扱いやすい部分もあるのでsubmit時に整形
      const formatted_answers = inputInterviewAnswers.map((answer) => {
        if (Array.isArray(answer.value)) {
          answer.value = answer.value.join()
          return answer
        } else {
          return answer
        }
      })
      const csrfToken = (document
        .getElementsByName("csrf-token")
        .item(0) as HTMLMetaElement).content
      const headers = new Headers()
      headers.set("Accept", "application/json")
      headers.set("Content-Type", "application/json")
      headers.set("X-CSRF-Token", csrfToken)
      const response = await fetch(
        `/api/customers/${params.customerId}/withdrawal_interview_infos`,
        {
          method: "POST",
          body: JSON.stringify({
            withdrawal_interview_infos: formatted_answers,
          }),
          headers,
        }
      )

      if (!response.ok) {
        const json = await response.json()
        alert(
          json.messages ||
            "アンケートの受付に失敗しました。お手数ですがサポートまで問い合わせお願い致します。"
        )
        return
      }

      if (withdrawInterviewSearchParams) {
        const id = withdrawInterviewSearchParams.medicalExaminationId
        fetch(
          `/api/medical_examinations/${id}/split_dispensing_prescriptions/cancel`,
          {
            method: "PATCH",
            headers,
          }
        ).then((response) => {
          if (response.ok) {
            history.push(
              `/thanks_withdrawal_interview?action=cancelPrescription`
            )
          } else {
            alert(
              "キャンセル処理に失敗しました。お手数ですがサポートまで問い合わせお願い致します。"
            )
          }
        })
      } else {
        history.push(`/thanks_withdrawal_interview`)
      }
    })()
  }

  // FIXME: アラートの表示が画面上部に出るため、メッセージに気付けない
  const handleErrorMessage = (message) => {
    setErrorMessage(message)
    setIsHideAlert(false)

    new Promise((resolve) => {
      setTimeout(() => {
        setIsHideAlert(true)
        resolve(null)
      }, 5000)
    }).then(() => {
      return setTimeout(() => {
        // isHideAlertがtrueのままだとtransitionが働いてfadeInしてしまうのでリセット
        // ↑ に伴ってerrorMessageをnullにすることで非表示にする
        // fadeOutに1秒かかるので1秒待ってからリセット
        setErrorMessage(null)
        setIsHideAlert(false)
      }, 1000)
    })
  }

  const TopDescription = (
    <>
      <h3 className="WebInterview__Title mb-5">
        {withdrawInterviewSearchParams
          ? "発送停止処理フォーム"
          : "サービス改善に関するアンケート"}
      </h3>
      <div className="row">
        <p className="mb-2 offset-md-3 col-md-6 col-sm-12">
          {withdrawInterviewSearchParams
            ? "発送停止処理フォームになります。（所要時間: 1分)"
            : "AGAスマクリのご利用を中止した方向けのアンケートです。（所要時間: 1分)"}
        </p>
        {!withdrawInterviewSearchParams && (
          <p className="mb-4 offset-md-3 col-md-6 col-sm-12">
            回答していただいた方の中から抽選で30名様にAmazonギフト券を1000円分をプレゼント致します。
          </p>
        )}
        <p className="mb-2 offset-md-3 col-md-6 col-sm-12">
          *回答内容は今後のサービス改善に役立てさせていただき、それ以外の目的には使用致しません。
        </p>
        <p className="mb-5 offset-md-3 col-md-6 col-sm-12">
          *利用再開のための無理な勧誘や電話等は一切行いませんのでご安心ください。
        </p>
      </div>
    </>
  )

  return (
    <>
      <IndexPresentation
        errorMessage={errorMessage}
        buttonText={
          withdrawInterviewSearchParams ? "停止処理をする" : "回答を送信する"
        }
        interviewItems={interviewItems}
        interviewChildItems={interviewChildItems}
        inputInterviewAnswers={inputInterviewAnswers}
        TopDescription={TopDescription}
        imageUploadPath={`/api/customers/${params.customerId}/test_result_images`}
        isShowHeadImageInput={false}
        isHideAlert={isHideAlert}
        setErrorMessage={handleErrorMessage}
        setInputInterviewAnswers={setInputInterviewAnswers}
        setInterviewItems={setInterviewItems}
        onSubmit={() => onSubmit()}
        canAnswer={canAnswer}
      ></IndexPresentation>
    </>
  )
}

export default withRouter(WithdrawalInterviewPage)
