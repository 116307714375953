import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"
import queryString from "query-string"
import { createRequestHeadersForCors } from "../../../utils/request_headers"
import { Card, CardBody } from "reactstrap"
import images from "../../../helpers/ui-helpers/ImageSources"
import LineAccountCard from "../../LineAccountCard"
import RakuRakuGuideSection from "../../RakuRakuGuideSection"
import styles from "../PrescriptionPage.module.scss"
import ScrollToTop from "../../../../utils/ScrollToTop"

import {
  Prescription,
  Delivery,
  ReceivingWay,
  ShipFrom,
  PaymentMethod,
  PaymentMethodType,
  ValidationError,
  PrescriptionStatusType,
  Card as CreditCard,
  PrescriptionDetail,
} from "../types"
import DeliveryForm from "../DeliveryForm"
import PaymentMethodForm from "../PaymentMethodForm"
import validation from "../validation"
import PrescriptionImagesPage from "../PrescriptionImagesPage"
import multipayment from "../../../utils/multipayment"

type Props = {} & RouteComponentProps<{ key: string }>

const PrescriptionPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props
  const [prescription, setPrescription] = useState<Prescription | null>()
  const [cards, setCards] = useState<CreditCard[]>([])
  const [delivery, setDelivery] = useState<Delivery>({
    receivingWay: ReceivingWay.AtHome,
    shipFrom: ShipFrom.AgaSmartClinic,
    addressZip: "",
    addressState: "",
    addressCity: "",
    addressLine1: "",
    addressLine2: "",
  } as Delivery)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({
    paymentMethod: PaymentMethodType.CreditCard,
    number: "",
    name: "",
    expMonth: "",
    expYear: "",
    cvc: "",
    sequence: "",
  } as PaymentMethod)
  const [amazonPaymentInfo, setAmazonPaymentInfo] = useState<string | null>(
    null
  )
  const [amazonPayButtonConfig, setAmazonPayButtonConfig] = useState<Object>({})
  const [validateErrors, setValidateErrors] = useState<ValidationError>({})
  const [submitting, setSubmitting] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [imagesShow, setImagesShow] = useState(false)
  const [usingNewCard, setUsingNewCard] = useState(false)
  const [addAnotherAddress, setAddAnotherAddress] = useState<boolean>(false)
  const [setAsDefault, setSetAsDefault] = useState<boolean>(false)
  const [isSplitDispensing, setIsSplitDispensing] = useState<boolean>(false)
  const [paymentTime, setPaymentTime] = useState<number | null>(null)

  const fetchPrescription = async () => {
    if (prescription) {
      return
    }
    return fetch(`/api/prescriptions/${params.key}`)
      .then((response) => {
        if (response.status === 404) {
          setNotFound(true)
          return
        }
        return response.json()
      })
      .then(async (prescription: Prescription) => {
        setPrescription(prescription)
        const newDelivery = {
          ...delivery,
          addressZip:
            prescription.address.address_zip ||
            prescription.customer.address_zip ||
            "",
          addressState:
            prescription.address.address_state ||
            prescription.customer.address_state ||
            "",
          addressCity:
            prescription.address.address_city ||
            prescription.customer.address_city ||
            "",
          addressLine1:
            prescription.address.address_line1 ||
            prescription.customer.address_line1 ||
            "",
          addressLine2:
            prescription.address.address_line2 ||
            prescription.customer.address_line2 ||
            "",
          shipFrom: prescription.address.ship_from,
          receivingWay: prescription.address.receiving_way,
        }

        let addressFromAmazonPay: null | Delivery = null
        if (fromAmazonPay && amazonCompletePayment !== "true") {
          addressFromAmazonPay = await fetchCheckoutSession(
            amazonCheckoutSessionId as string,
            newDelivery
          )
        }

        if (addressFromAmazonPay && !newDelivery.addressZip.length) {
          setDelivery(addressFromAmazonPay)
        } else {
          setDelivery(newDelivery)
        }

        if (newDelivery.addressZip.length) {
          setAddAnotherAddress(true)
        }
        setIsSplitDispensing(prescription.is_split_dispensing)
      })
  }

  const fetchCards = async () => {
    const res = await fetch("/api/cards", {
      headers: createRequestHeadersForCors(),
    })
    const cardResponse = (await res.json()).cards
    setCards(cardResponse)
  }

  const fetchCheckoutSession = async (
    checkoutSessionId: string,
    newDelivery: Delivery
  ) => {
    setPaymentMethod({
      ...paymentMethod,
      paymentMethod: PaymentMethodType.AmazonPay,
    })
    const res = await fetch(
      `/api/amazonpay/checkout_session?checkout_session_id=${checkoutSessionId}`,
      { headers: createRequestHeadersForCors() }
    )
    if (!res.ok) {
      alert(
        "Amazon Payから情報取得ができませんでした。時間を置いてから再度お試しください"
      )
      return null
    }
    const body = await res.json()
    const address = body.address_info
    const fromAmazonDelivery = {
      ...newDelivery,
      addressZip: address.zip || "",
      addressState: address.state || "",
      addressCity: address.city || "",
      addressLine1: address.line1 || "",
      addressLine2: address.line2 || "",
    }
    setAmazonPaymentInfo(body.payment_info)
    return fromAmazonDelivery
  }

  const fetchAmazonPayButtonConfig = async () => {
    const res = await fetch(
      `/api/amazonpay/button_config?prescription_id=${params.key}`,
      { headers: createRequestHeadersForCors() }
    )
    const config = await res.json()
    setAmazonPayButtonConfig(config)
  }

  const createRequestParamsForPurchase = () => {
    return {
      receiving_way: delivery.receivingWay,
      ship_from: delivery.shipFrom,
      address_zip: delivery.addressZip,
      address_state: delivery.addressState,
      address_city: delivery.addressCity,
      address_line1: delivery.addressLine1,
      address_line2: delivery.addressLine2,
      add_another_address: addAnotherAddress,
      set_as_default: setAsDefault,
      payment_method: paymentMethod.paymentMethod,
    }
  }

  const updatePrescription = async (): Promise<boolean> => {
    const res = await fetch(`/api/prescriptions/${params.key}`, {
      method: "PATCH",
      body: JSON.stringify(createRequestParamsForPurchase()),
      headers: createRequestHeadersForCors(),
    })
    return res.status === 204
  }

  const updateCheckoutSession = async (checkoutSessionId) => {
    const res = await fetch(
      `/api/amazonpay/update_checkout_session?prescription_key=${params.key}&checkout_session_id=${checkoutSessionId}`,
      {
        headers: createRequestHeadersForCors(),
        method: "PATCH",
        body: JSON.stringify(createRequestParamsForPurchase()),
      }
    )
    const json = await res.json()
    window.location.href = json.url
    return
  }

  const purchase = async (sequence: string | null, token: string | null) => {
    const request_params = fromAmazonPayCompleted
      ? {
          payment_method: PaymentMethodType.AmazonPay,
          amazon_checkout_session_id: amazonCheckoutSessionId,
        }
      : {
          receiving_way: delivery.receivingWay,
          ship_from: delivery.shipFrom,
          address_zip: delivery.addressZip,
          address_state: delivery.addressState,
          address_city: delivery.addressCity,
          address_line1: delivery.addressLine1,
          address_line2: delivery.addressLine2,
          payment_method: paymentMethod.paymentMethod,
          add_another_address: addAnotherAddress,
          set_as_default: setAsDefault,
          sequence,
          token,
          payment_time: paymentTime,
        }

    return fetch(`/api/prescriptions/${params.key}/purchases`, {
      method: "POST",
      body: JSON.stringify(request_params),
      headers: createRequestHeadersForCors(),
    })
      .then((response) => {
        if (response.status === 403) {
          alert(
            "購入に失敗しました。お使いのブラウザを変えて、もう一度お試しください。"
          )
          setSubmitting(false)
          return
        }
        if ([400, 422].includes(response.status)) {
          alert("購入に失敗しました。")
          setSubmitting(false)
          window.location.href = window.location.href.split("?")[0]
          return
        }
        return response.json()
      })
      .then((prescription: Prescription) => {
        setPrescription(prescription)
        if (prescription.status === "purchase_failed") {
          alert(
            "購入に失敗しました。お使いのブラウザを変えて、もう一度お試しください。"
          )
        }
      })
  }

  const amazonCheckoutSessionId = queryString.parse(window.location.search)[
    "amazonCheckoutSessionId"
  ]
  const amazonCompletePayment = queryString.parse(window.location.search)[
    "amazon_pay_complete"
  ]

  // amazonPayボタン => amazonPay => Appに戻ってきた状態
  const fromAmazonPay = !!amazonCheckoutSessionId

  // 購入ボタン => amazonPay => Appに戻ってきた状態
  const fromAmazonPayCompleted =
    fromAmazonPay && amazonCompletePayment === "true"

  // 購入ボタン => amazonPay => Appに戻ってきた直後の状態
  const beforePurchaseAmazonPayCompleted =
    fromAmazonPayCompleted &&
    prescription &&
    prescription.status !== PrescriptionStatusType.Purchased

  // amazonPayを選択している& amazonPayボタンを押していない
  const beforeUseAmazonPay =
    paymentMethod.paymentMethod === PaymentMethodType.AmazonPay &&
    !fromAmazonPay

  useEffect(() => {
    fetchPrescription().then(() => {
      fetchAmazonPayButtonConfig()
    })
    fetchCards()
  }, [prescription, params, setCards])

  useEffect(() => {
    if (fromAmazonPayCompleted) {
      purchase(null, null)
      return
    }
  }, [])

  if (notFound) {
    return <p>処方箋情報が見つかりませんでした。</p>
  }

  if (!prescription || beforePurchaseAmazonPayCompleted) {
    return <p>読込中...</p>
  }

  const getCreditCardToken = () => {
    return multipayment.getToken({
      holdername: paymentMethod.name,
      cardno: paymentMethod.number,
      expire: `20${paymentMethod.expYear}${paymentMethod.expMonth}`,
      securitycode: paymentMethod.cvc,
    })
  }

  const createCard = async (token: string | null) => {
    const res = await fetch("/api/cards", {
      method: "POST",
      body: JSON.stringify({
        token,
      }),
      headers: createRequestHeadersForCors(),
    })
    return (await res.json()).sequence
  }

  const onClickHandler = async () => {
    const errors = validation(delivery, paymentMethod, addAnotherAddress)
    setValidateErrors(errors)
    if (submitting || Object.keys(errors).length > 0) {
      return
    }

    setSubmitting(true)

    if (paymentMethod.paymentMethod === PaymentMethodType.AmazonPay) {
      updateCheckoutSession(amazonCheckoutSessionId)
      return
    }

    let sequence: string | null = paymentMethod.sequence
    let token: string | null = null

    // カードが選択されていない && 新規カードを利用する場合はカード登録を行う
    if (
      paymentMethod.paymentMethod === PaymentMethodType.CreditCard &&
      (!paymentMethod.sequence.length || usingNewCard)
    ) {
      try {
        token = await getCreditCardToken()
        sequence = await createCard(token)
      } catch {
        alert("クレジットカード情報をお確かめ下さい。")
        setSubmitting(false)
        return
      }
    } else if (
      [PaymentMethodType.DebitCard, PaymentMethodType.PrepaidCard].includes(
        paymentMethod.paymentMethod
      )
    ) {
      try {
        token = await getCreditCardToken()
      } catch {
        alert("カード情報をお確かめ下さい。")
        setSubmitting(false)
        return
      }
    }
    try {
      await purchase(sequence, token)
    } catch {
      alert("購入に失敗しました。")
    }

    setSubmitting(false)
  }

  const toggleImagesView = () => {
    setImagesShow(!imagesShow)
  }

  const examinationSpan = prescription.days - 10
  const nextExamination = moment(prescription.created_at)
    .add(examinationSpan, "days")
    .format("YYYY年MM月DD日")
  const lineIconColor = { color: "#06c755" }
  const lineBackgroundColor = { backgroundColor: "#06c755" }
  const lineLinkStyle = {
    backgroundColor: "#06c755",
    borderRadius: "15px 15px 15px 15px",
  }

  const displayText = (prescriptionDetail: PrescriptionDetail) => {
    if (prescriptionDetail.medicine_name_for_customer) {
      return `${prescriptionDetail.medicine_name_for_customer}　${prescriptionDetail.dosage_per_day}`
    }

    if (prescriptionDetail.spec == "") {
      return `${prescriptionDetail.medicine_name}　${prescriptionDetail.dosage_per_day}`
    } else {
      return `${prescriptionDetail.medicine_name}${prescriptionDetail.spec}　${prescriptionDetail.dosage_per_day}`
    }
  }

  const renderPurchase = () => (
    <>
      <section className="container-nallow">
        <h2>お薬情報</h2>
        <table>
          <tbody>
            {prescription.prescription_details.map((prescription_detail) => (
              <tr key={prescription_detail.medicine_name}>
                <td>{displayText(prescription_detail)}</td>
              </tr>
            ))}
            <tr>
              <th colSpan={2}>
                金額: {prescription.amount.toLocaleString()}円 (税込)
              </th>
            </tr>
          </tbody>
        </table>
        <span
          role="button"
          className="small mt-2 text-primary"
          onClick={toggleImagesView}
        >
          錠剤の画像はこちら
        </span>
      </section>
      <DeliveryForm
        delivery={delivery}
        validateErrors={validateErrors}
        addAnotherAddress={addAnotherAddress}
        setAsDefault={setAsDefault}
        prescriptionKey={prescription.key}
        setSetAsDefault={setSetAsDefault}
        setAddAnotherAddress={setAddAnotherAddress}
        onChange={setDelivery}
      />
      <PaymentMethodForm
        paymentMethod={paymentMethod}
        cards={cards}
        usingNewCard={usingNewCard}
        setUsingNewCard={setUsingNewCard}
        validateErrors={validateErrors}
        onChange={setPaymentMethod}
        amazonPaymentInfo={amazonPaymentInfo}
        amazonPayButtonConfig={amazonPayButtonConfig}
        updatePrescription={updatePrescription}
        isSplitDispensing={isSplitDispensing}
        onChangePaymentTime={setPaymentTime}
      />
      {paymentMethod.paymentMethod === PaymentMethodType.BankTransfer && (
        <section className="mt-4">
          <p className="text-center text-danger">
            購入するボタンをクリック後
            <br />
            振込口座情報が記載されたページに遷移します。
          </p>
        </section>
      )}
      {!beforeUseAmazonPay && (
        <button
          className="btn btn-primary form-control"
          onClick={onClickHandler}
          disabled={submitting}
        >
          購入する
        </button>
      )}
    </>
  )

  const renderThanksCreditCard = () => (
    <section className="container-nallow">
      <h2 className="text-center font-weight-bold">
        購入ありがとうございます！
      </h2>
      <p className="text-center font-weight-bold">
        お薬到着予定日：
        {moment(prescription.created_at).add(2, "days").format("MM/DD")}〜
        {moment(prescription.created_at).add(5, "days").format("MM/DD")}
      </p>
      <p className="text-center small">
        ※お届け先にヤマト営業所を選択した方は到着次第、メールにてお知らせします。
      </p>
      <h2 className="text-center text-primary font-weight-bold mt-5">
        2回目以降のお薬購入には
        <br />
        医師との診察が必要です
      </h2>
      <p className="text-center mt-5">
        {prescription.customer.name}様の次回診療時期
        <br />
        {nextExamination}頃
      </p>
      <p className="text-center font-weight-bold small">
        診察時期が近くなりましたら
        <br />
        <span className="text-danger">診察のご予約</span>をお願いいたします。
      </p>
      <p className="text-center">
        <a
          href="https://lin.ee/NEYyKTz"
          className="btn btn-success p-0 pr-2 border-0"
          style={lineBackgroundColor}
        >
          <span className="fa-stack fa-lg">
            <i className="fas fa-square fa-stack-2x fa-inverse" />
            <i className="far fa-square fa-stack-2x" style={lineIconColor} />
            <i className="fab fa-line fa-stack-2x" style={lineIconColor} />
          </span>
          LINEより次回予約が可能です
        </a>
        <br />
        <span className="small">AGAスマクリ公式ライン</span>
      </p>
    </section>
  )

  const renderThanksBankTransfer = () => (
    <>
      <ScrollToTop />
      <section
        className={[styles.thanksPurchase, "container-nallow"].join(" ")}
      >
        <h3 className="text-center font-weight-bold">
          購入ありがとうございます！
        </h3>
        <br />
        <p className="text-center font-weight-bold">
          お薬お届け：振り込みが確認でき次第3日以内
        </p>
        <p className="text-center small">
          ※お届け先にヤマト営業所を選択した方は、発送完了
          <br />
          メールに記載の追跡番号にて営業所や到着確認を
          <br />
          お願いいたします。
        </p>
        <div className="d-flex justify-content-center">
          <div
            className="text-center mt-3 p-3"
            style={{ background: "#E4EDF6" }}
          >
            <h4 className="text-danger font-weight-bold">
              <img
                className="w-30 mt-2 mr-3"
                src={require("assets/important_notice.png")}
                alt="メガホン"
              />
              <span>重要なお知らせ</span>
            </h4>
            <span className="text-danger font-weight-bold h4">
              振込先が変更
            </span>
            となりました。
            <p className="mt-2 font-weight-bold">
              お振込み期限: {prescription.bank_transfer_deadline}
            </p>
            <p></p>
            <div className="border border-primary font-weight-bold p-2">
              <br />
              銀行: {prescription.customer_bank_account?.bank_name}
              <br />
              支店名: {prescription.customer_bank_account?.branch_name}
              <br />
              口座番号: {prescription.customer_bank_account?.account_number}
              <br />
              振込金額: {prescription.amount.toLocaleString()}円 (税込)
              <br />
              振込依頼人名を 「{prescription.code} お名前カナ」
              <br />
              <br />
            </div>
            <p className="mt-2">
              本日より4週間以内の入金をお願いいたします。
              <br />
              4週間を超えてしまいますと、処方箋の有効期限切れとなり、
              <br />
              お薬が発送できません。
            </p>
          </div>
        </div>
        {!isSplitDispensing && (
          <>
            <h4 className="text-center font-weight-bold mt-5">
              2回目以降のお薬購入には
              <br />
              医師との診察が必要です
            </h4>
            <p className="text-center mt-5">
              {prescription.customer.name}様の次回診療時期
              <br />
              {nextExamination}頃
            </p>
            <p className="text-center small">
              診察時期が近くなりましたら
              <br />
              <span>診察のご予約</span>をお願いいたします！
            </p>
            <br />
            <div className="text-center">
              <a
                href="https://lin.ee/NEYyKTz"
                className={`btn btn-success p-0 pr-2 border-0 `}
                style={lineLinkStyle}
              >
                <span className="fa-stack fa-lg">
                  <i className="fas fa-square fa-stack-2x fa-inverse" />
                  <i
                    className="far fa-square fa-stack-2x"
                    style={lineIconColor}
                  />
                  <i
                    className="fab fa-line fa-stack-2x"
                    style={lineIconColor}
                  />
                </span>
                LINEより次回予約が可能です
              </a>
              <br />
              <p className="mt-2">
                <img
                  className="mr-2"
                  src={require("assets/smart_clinic_logo_small_gray.png")}
                  alt="AGAスマクリ"
                />
                <span className="small">公式ライン</span>
              </p>
            </div>
          </>
        )}
      </section>
    </>
  )

  const renderThanksSplitDispensing = () => (
    <>
      <ScrollToTop />
      <section
        className={[styles.thanksPurchase, "container-nallow"].join(" ")}
        data-testid="thanks-split-dispending"
      >
        <h3 className="h4 text-center font-weight-bold mb-4">
          ご購入
          <br />
          ありがとうございます
        </h3>
        <p className="text-center small mb-4">
          決済内容の確認メールをお送りしています。
          <br />
          ご確認よろしくお願いいたします。
        </p>
        <Card className="mb-4">
          <CardBody>
            <div className="d-flex justify-content-center align-items-center">
              <img src={images.iconDelivery} alt="デリバリー" />
              <p className="mb-0 ml-2">お薬到着予定日</p>
            </div>
            <hr />
            <p className="mb-0 h5 font-weight-normal">
              {moment(prescription.created_at)
                .add(2, "days")
                .format("MM月DD日(ddd)")}
              ~
              {moment(prescription.created_at)
                .add(5, "days")
                .format("MM月DD日(ddd)")}
            </p>
          </CardBody>
        </Card>
        <hr className="mt-4 mb-4" />
        <RakuRakuGuideSection />
        <LineAccountCard />
      </section>
    </>
  )

  const renderCanceled = () => (
    <section className={[styles.thanksPurchase, "container-nallow"].join(" ")}>
      <h2 className="text-center">こちらの処方箋はキャンセルされました</h2>
      <p className="text-center mt-5">
        ご不明点のある場合は運営までお問い合わせください。
      </p>
    </section>
  )

  const renderExpired = () => (
    <section className={[styles.thanksPurchase, "container-nallow"].join(" ")}>
      <h2 className="text-center">処方箋の有効期限が切れております</h2>
      <p className="text-center mt-5">
        当クリニックではお客様の安全と健康を第一に考え、
        <br />
        処方箋の有効期限を６日間と定めております。
        <br />
        再度お薬をお求めの場合は下記URLより、再度診療のご予約が可能でございます。
        <br />
        <br />
        ↓診察予約URL
        <br />
        <a href={prescription.medical_examination_url}>
          {prescription.medical_examination_url}
        </a>
        <br />
        <br />
        お手数をおかけしてしまい、誠に申し訳ございません。
        <br />
        何卒よろしくお願い致します。
        <br />
      </p>
    </section>
  )

  if (prescription.status === PrescriptionStatusType.Canceled) {
    return renderCanceled()
  } else if (prescription.status === PrescriptionStatusType.Expired) {
    return renderExpired()
  } else if (
    prescription.status !== PrescriptionStatusType.BeforePurchase &&
    prescription.status !== PrescriptionStatusType.PurchaseFailed
  ) {
    if (prescription.is_split_dispensing) {
      if (prescription.payment_method === PaymentMethodType.BankTransfer) {
        // 分割調剤の場合でも銀行口座の情報を表示する必要があるため
        return renderThanksBankTransfer()
      } else {
        return renderThanksSplitDispensing()
      }
    } else {
      if (prescription.payment_method === PaymentMethodType.BankTransfer) {
        return renderThanksBankTransfer()
      } else {
        return renderThanksCreditCard()
      }
    }
  }
  if (imagesShow) {
    return PrescriptionImagesPage(toggleImagesView)
  }

  return renderPurchase()
}

export default withRouter(PrescriptionPage)
