import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps, useLocation } from "react-router-dom"
import moment from "moment"

import ScheduleCalendar from "../ScheduleCalendar"
import { Customer, ScheduleType } from "../../types"

type Props = {} & RouteComponentProps<{
  customerId: string
  medicalExaminationId: string
}>

const ScheduleCustomerMedicalExaminationPage: React.FC<Props> = (props) => {
  const {
    history,
    match: { params },
  } = props
  const [customer, setCustomer] = useState<Customer | null>(null)
  const [isFirstExamination, setIsFirstExamination] = useState<boolean>(false)

  const search = useLocation().search
  const query = new URLSearchParams(search)
  const medicalExaminationId = query.get("medicalExaminationId")

  const onSelectTimeHandler = (date) => {
    if (
      window.location.pathname.split("/")[1] ===
      "customer_schedule_first_medical_examinations"
    ) {
      history.push(
        `/customer_schedule_first_medical_examinations/${
          params.customerId
        }/${moment(date).format("YYYYMMDDHHmm")}${window.location.search}`
      )
    } else if (
      window.location.pathname.split("/")[1] ===
      "customer_schedule_unable_to_prescribe_medical_examinations"
    ) {
      history.push(
        `/customer_schedule_unable_to_prescribe_medical_examinations/${
          params.customerId
        }/${moment(date).format("YYYYMMDDHHmm")}${window.location.search}`
      )
    } else {
      history.push(
        `/customer_schedule_medical_examinations/${params.customerId}/${moment(
          date
        ).format("YYYYMMDDHHmm")}${
          window.location.search
        }?medicalExaminationId=${medicalExaminationId}`
      )
    }
  }

  useEffect(() => {
    ;(async () => {
      const response = await fetch(`/api/customers/${params.customerId}`)
      const customer = await response.json()
      setCustomer(customer)
      const is_first_examination = await fetch(
        `/api/is_first_examination/${params.customerId}`
      )
      const res = await is_first_examination.json()
      setIsFirstExamination(res.data)
    })()
  }, [params])

  if (customer === null) {
    return null
  }
  // if (isFirstExamination && window.location.pathname.split("/")[1] !== 'customer_schedule_first_medical_examinations') {
  //   return null
  // }
  // if (!isFirstExamination && window.location.pathname.split("/")[1] === 'customer_schedule_first_medical_examinations') {
  //   return null
  // }

  return (
    <>
      <div className="text-center reservation-title">予約フォーム</div>
      <div className="text-center">ご希望の日時をご選択ください</div>
      <ScheduleCalendar
        type={ScheduleType.medicalExamination}
        onSelectTime={onSelectTimeHandler}
      />
    </>
  )
}

export default withRouter(ScheduleCustomerMedicalExaminationPage)
