import React, { useState, useEffect } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import moment from "moment"
import ThanksSplitDispensing from "../ThanksSplitDispensing"

import {
  Prescription,
  Delivery,
  ReceivingWay,
  ShipFrom,
  PaymentMethod,
  PaymentMethodType,
  ValidationError,
  PrescriptionStatusType,
  PrescriptionDetail,
} from "../types"
import DeliveryForm from "../DeliveryForm"
import validation from "../validation"
import PrescriptionImagesPage from "../PrescriptionImagesPage"
import NewCreditCardForm from "../NewCreditCardForm"
import { updateMe } from "../../../../components/pages/CustomerDetailChange/CustomerDetailChangePageController"
import multipayment from "../../../utils/multipayment"
import { createRequestHeadersForCors } from "../../../utils/request_headers"

type Props = {} & RouteComponentProps<{ key: string }>

const TrialPrescriptionPage: React.FC<Props> = (props) => {
  const {
    match: { params },
  } = props
  const [prescription, setPrescription] = useState<Prescription | null>()
  const [delivery, setDelivery] = useState<Delivery>({
    receivingWay: ReceivingWay.AtHome,
    shipFrom: ShipFrom.AgaSmartClinic,
    addressZip: "",
    addressState: "",
    addressCity: "",
    addressLine1: "",
    addressLine2: "",
  } as Delivery)
  const [validateErrors, setValidateErrors] = useState<ValidationError>({})
  const [submitting, setSubmitting] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [imagesShow, setImagesShow] = useState(false)
  const [addAnotherAddress, setAddAnotherAddress] = useState<boolean>(false)
  const [setAsDefault, setSetAsDefault] = useState<boolean>(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>({
    paymentMethod: PaymentMethodType.BankTransfer,
    number: "",
    name: "",
    expMonth: "",
    expYear: "",
    cvc: "",
    sequence: "",
  } as PaymentMethod)
  const [enteredPaymentInfo, setEnterdPaymentInfo] = useState<boolean>(false)

  const fetchPrescription = () => {
    if (prescription) {
      return
    }
    fetch(`/api/prescriptions/${params.key}`)
      .then((response) => {
        if (response.status === 404) {
          setNotFound(true)
          return
        }
        return response.json()
      })
      .then((prescription: Prescription) => {
        setPrescription(prescription)
        const newDelivery = {
          ...delivery,
          addressZip: prescription.customer.address_zip || "",
          addressState: prescription.customer.address_state || "",
          addressCity: prescription.customer.address_city || "",
          addressLine1: prescription.customer.address_line1 || "",
          addressLine2: prescription.customer.address_line2 || "",
          addAnotherAddress: addAnotherAddress,
        }
        setDelivery(newDelivery)
      })
  }

  useEffect(() => {
    fetchPrescription()
  }, [prescription, params, delivery])

  if (notFound) {
    return <p>処方箋情報が見つかりませんでした。</p>
  }
  if (!prescription) {
    return <p>読込中...</p>
  }

  const purchase = async (sequence: string | null) => {
    const headers = new Headers()
    headers.set("Accept", "application/json")
    headers.set("Content-Type", "application/json")
    headers.set("X-Requested-With", "XMLHttpRequest")

    return fetch(`/api/prescriptions/${params.key}/purchases`, {
      method: "POST",
      body: JSON.stringify({
        receiving_way: delivery.receivingWay,
        ship_from: delivery.shipFrom,
        address_zip: delivery.addressZip,
        address_state: delivery.addressState,
        address_city: delivery.addressCity,
        address_line1: delivery.addressLine1,
        address_line2: delivery.addressLine2,
        payment_method: paymentMethod.paymentMethod,
        sequence,
      }),
      headers,
    })
      .then((response) => {
        if (response.status === 403) {
          alert(
            "購入に失敗しました。お使いのブラウザを変えて、もう一度お試しください。"
          )
          setSubmitting(false)
          return
        }
        return response.json()
      })
      .then((prescription: Prescription) => setPrescription(prescription))
  }

  const onClickHandler = async () => {
    const errors = validation(delivery, paymentMethod, addAnotherAddress)
    setValidateErrors(errors)
    if (submitting || Object.keys(errors).length > 0) {
      return
    }

    setSubmitting(true)

    let sequence: string | null = paymentMethod.sequence

    try {
      await purchase(sequence)
      onChangePaymentMethodType(PaymentMethodType.CreditCard)
    } catch {
      alert("購入に失敗しました。")
    }

    setSubmitting(false)
  }

  const toggleImagesView = () => {
    setImagesShow(!imagesShow)
  }

  const onChangePaymentMethodType = (paymentMethodType: PaymentMethodType) => {
    const newPaymentMethod = {
      ...paymentMethod,
      paymentMethod: paymentMethodType,
    }
    setPaymentMethod(newPaymentMethod)
  }

  const onChangePaymentMethod = (paymentMethod: PaymentMethod) => {
    setPaymentMethod(paymentMethod)
  }

  const onSubmitPaymentMethod = async () => {
    if (paymentMethod.paymentMethod !== PaymentMethodType.CreditCard) {
      setEnterdPaymentInfo(true)
      return
    }
    const errors = validation(delivery, paymentMethod, addAnotherAddress)
    setValidateErrors(errors)
    if (submitting || Object.keys(errors).length > 0) {
      return
    }

    setSubmitting(true)

    try {
      await updateMe({ payment_method: "credit_card" })
      const token = await getCreditCardToken()
      await createCard(token)
      setEnterdPaymentInfo(true)
    } catch {
      alert("登録に失敗しました。")
    }

    setSubmitting(false)
  }

  const getCreditCardToken = () => {
    return multipayment.getToken({
      holdername: paymentMethod.name,
      cardno: paymentMethod.number,
      expire: `20${paymentMethod.expYear}${paymentMethod.expMonth}`,
      securitycode: paymentMethod.cvc,
    })
  }

  const createCard = (token: string | null) => {
    return fetch("/api/cards", {
      method: "POST",
      body: JSON.stringify({
        token,
      }),
      headers: createRequestHeadersForCors(),
    })
  }

  const examinationSpan = prescription.days - 10
  const nextExamination = moment(prescription.created_at)
    .add(examinationSpan, "days")
    .format("YYYY年MM月DD日")
  const lineIconColor = { color: "#06c755" }
  const lineBackgroundColor = { backgroundColor: "#06c755" }

  const displayText = (prescriptionDetail: PrescriptionDetail) => {
    if (prescriptionDetail.medicine_name_for_customer) {
      return `${prescriptionDetail.medicine_name_for_customer}　${prescriptionDetail.dosage_per_day}`
    }

    if (prescriptionDetail.spec == "") {
      return `${prescriptionDetail.medicine_name}　${prescriptionDetail.dosage_per_day}`
    } else {
      return `${prescriptionDetail.medicine_name}${prescriptionDetail.spec}　${prescriptionDetail.dosage_per_day}`
    }
  }

  const renderPurchase = () => (
    <>
      <section>
        <h2>お薬情報</h2>
        <table>
          <tbody>
            {prescription.prescription_details.map((prescription_detail) => (
              <tr key={prescription_detail.medicine_name}>
                <td>{displayText(prescription_detail)}</td>
              </tr>
            ))}
            <tr>
              <th colSpan={2}>
                金額: {prescription.amount.toLocaleString()}円 (税込)
              </th>
            </tr>
          </tbody>
        </table>
        <span
          role="button"
          className="small mt-2 text-primary"
          onClick={toggleImagesView}
        >
          錠剤の画像はこちら
        </span>
      </section>
      <DeliveryForm
        delivery={delivery}
        validateErrors={validateErrors}
        isTrial={false}
        setAsDefault={setAsDefault}
        prescriptionKey={prescription.key}
        setSetAsDefault={setSetAsDefault}
        addAnotherAddress={addAnotherAddress}
        setAddAnotherAddress={setAddAnotherAddress}
        onChange={setDelivery}
      />

      <button
        className="btn btn-primary form-control"
        onClick={onClickHandler}
        disabled={submitting}
      >
        配送手続きをする
      </button>
    </>
  )

  const renderThanksBankTransfer = () => (
    <section>
      <h2 className="text-center font-weight-bold">
        お手続きありがとうございます！
      </h2>
      <p className="text-center font-weight-bold">
        お薬到着予定日：
        {moment(prescription.created_at).add(2, "days").format("MM/DD")}〜
        {moment(prescription.created_at).add(5, "days").format("MM/DD")}
      </p>
      <p className="text-center small">
        ※お届け先にヤマト営業所を選択した方は到着次第、メールにてお知らせします。
      </p>
      <h2 className="text-center text-primary font-weight-bold mt-5">
        次回以降のお薬購入には
        <br />
        医師との診察が必要です
      </h2>
      <p className="text-center mt-5">
        {prescription.customer.name}様の次回診療時期
        <br />
        {nextExamination}頃
      </p>
      <p className="text-center font-weight-bold small">
        診察時期が近くなりましたら
        <br />
        <span className="text-danger">診察のご予約</span>をお願いいたします。
      </p>
      <p className="text-center">
        <a
          href="https://lin.ee/NEYyKTz"
          className="btn btn-success p-0 pr-2 border-0"
          style={lineBackgroundColor}
        >
          <span className="fa-stack fa-lg">
            <i className="fas fa-square fa-stack-2x fa-inverse" />
            <i className="far fa-square fa-stack-2x" style={lineIconColor} />
            <i className="fab fa-line fa-stack-2x" style={lineIconColor} />
          </span>
          LINEより次回予約が可能です
        </a>
        <br />
        <span className="small">AGAスマクリ公式ライン</span>
      </p>
    </section>
  )

  const renderCanceled = () => (
    <section>
      <h2 className="text-center">こちらの処方箋はキャンセルされました</h2>
      <p className="text-center mt-5">
        ご不明点のある場合は運営までお問い合わせください。
      </p>
    </section>
  )

  const renderExpired = () => (
    <section>
      <h2 className="text-center">処方箋の有効期限が切れております</h2>
      <p className="text-center mt-5">
        当クリニックではお客様の安全と健康を第一に考え、
        <br />
        処方箋の有効期限を６日間と定めております。
        <br />
        再度お薬をお求めの場合は下記URLより、再度診療のご予約が可能でございます。
        <br />
        <br />
        ↓診察予約URL
        <br />
        <a href={prescription.medical_examination_url}>
          {prescription.medical_examination_url}
        </a>
        <br />
        <br />
        お手数をおかけしてしまい、誠に申し訳ございません。
        <br />
        何卒よろしくお願い致します。
        <br />
      </p>
    </section>
  )

  const renderPaymentForm = () => (
    <>
      <section>
        <h2 className="text-center font-weight-bold">
          購入手続きが完了しました。
          <br />
          次回以降の支払方法の登録をお願いいたします。
        </h2>
        <p className="text-center small">
          ※今回の支払いは初回無料キャンペーンにより0円になります。
          <br />
          次回購入は発送前であればいつでもキャンセルは可能です。
        </p>
      </section>
      <section>
        <div className="form-group row mt-4">
          <label className="col-form-label col-md-2"></label>
          <div className="col-md-10">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="deliveryTypeHome"
                name="payment_method"
                checked={
                  paymentMethod.paymentMethod === PaymentMethodType.CreditCard
                }
                onChange={() =>
                  onChangePaymentMethodType(PaymentMethodType.CreditCard)
                }
              />
              <label className="form-check-label" htmlFor="deliveryTypeHome">
                クレジットカード
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id="deliveryTypeOther"
                name="payment_method"
                checked={
                  paymentMethod.paymentMethod === PaymentMethodType.Other
                }
                onChange={() =>
                  onChangePaymentMethodType(PaymentMethodType.Other)
                }
              />
              <label className="form-check-label" htmlFor="deliveryTypeOther">
                その他（銀行振込もしくはAmazonPay）
                <br />
                ※銀行振込にかかる手数料はお客様のご負担となります。
              </label>
            </div>
          </div>
        </div>
        {paymentMethod.paymentMethod === PaymentMethodType.CreditCard && (
          <NewCreditCardForm
            paymentMethod={paymentMethod}
            onChange={onChangePaymentMethod}
            validateErrors={validateErrors}
          />
        )}
        <button
          className="btn btn-primary form-control"
          onClick={onSubmitPaymentMethod}
          disabled={submitting}
        >
          支払い方法を登録する
        </button>
      </section>
    </>
  )

  if (prescription.status === PrescriptionStatusType.Canceled) {
    return renderCanceled()
  } else if (prescription.status === PrescriptionStatusType.Expired) {
    return renderExpired()
  } else if (
    prescription.status !== PrescriptionStatusType.BeforePurchase &&
    prescription.status !== PrescriptionStatusType.PurchaseFailed
  ) {
    if (!enteredPaymentInfo) {
      return renderPaymentForm()
    }
    if (prescription.is_split_dispensing) {
      return <ThanksSplitDispensing prescription={prescription} />
    } else {
      return renderThanksBankTransfer()
    }
  }

  if (imagesShow) {
    return PrescriptionImagesPage(toggleImagesView)
  }

  return renderPurchase()
}

export default withRouter(TrialPrescriptionPage)
