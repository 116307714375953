import React, { useState, useEffect } from "react"
import { InterviewItem } from "./types"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  data: InterviewItem
  handleChange: (id, value) => void
  initialValue?: string | number
}

const FormSingleSelect: React.FC<Props> = (props) => {
  const [value, setValue] = useState<string | number>()
  const [inputFlg, setInputFlg] = useState(false)
  const handleChange = (e) => {
    if (!inputFlg) {
      window.dataLayer.push({
        event: "item_input",
        itemTitle: props.data.title,
      })
      setInputFlg(true)
    }
    const value = e.target.value
    const id = props.data.id
    setValue(value)
    props.handleChange(id, value)
  }

  useEffect(() => {
    setValue(props.initialValue || "")
  }, [props.initialValue])

  return (
    <>
      {props.data.option_list &&
        props.data.option_list.split(",").map((option, index) => {
          return (
            <div key={index}>
              <input
                type="radio"
                value={option}
                checked={value === option}
                id={`${props.data.id}_${index}`}
                className="WebInterview__input-radio"
                onChange={handleChange}
              />
              <label
                htmlFor={`${props.data.id}_${index}`}
                className="WebInterview__input-radio_label w-100"
              >
                {option}
              </label>
            </div>
          )
        })}
    </>
  )
}

export default FormSingleSelect
