import React from "react"

import IntraDayMinutes from "./IntraDayMinutes"
import {
  SchedulableDate as SchedulableDateModel,
  SchedulableTime,
} from "../../types"

type Props = {
  times: SchedulableDateModel
  currentDay: Date
  onSelectTime: (date: Date) => void
}

type SchedulableDateResponse = {
  schedulable_times: any[]
}

const IntraDayHours: React.FC<Props> = (props) => {
  const { times, currentDay } = props
  const arr = times.times
  let countEachHours = Array(13)
  countEachHours.fill(0)

  arr.forEach((element) => {
    if (element.isSchedulable) {
      countEachHours[element.startAt.getHours() - 9]++
    }
  })

  let inputField
  let targetHour = ""

  const handleHourClick = (e) => {
    e.preventDefault()
    const target = e.currentTarget
    targetHour = target.childNodes[1].innerText.split(":")[0]

    console.log(
      `target.childNodes[2]: ${target.childNodes[2]}  target.childNodes[2].name: ${target.childNodes[2].name}   targetHour: ${targetHour}`
    )

    if (target.childNodes[2].name === "none") {
      target.childNodes[2].style.transform = "rotate(" + 90 + "deg)"
      target.childNodes[2].setAttribute("name", "clicked")
      target.childNodes[2].parentNode.parentNode.parentNode.childNodes.forEach(
        (node) => {
          if (node.childNodes[1].innerText.split(":")[0] === targetHour) {
            node.childNodes[1].setAttribute("class", "hour-open")
            target.childNodes[2].parentNode.parentNode.parentNode.childNodes.forEach(
              (element, index) => {
                const btn = element.childNodes[0].childNodes[2]

                if (
                  element.childNodes[1].innerText.split(":")[0] !==
                    targetHour &&
                  btn.name === "clicked"
                ) {
                  btn.style.transform = "rotate(" + 0 + "deg)"
                  btn.setAttribute("name", "none")

                  target.childNodes[2].parentNode.parentNode.parentNode.childNodes[
                    index
                  ].childNodes[1].setAttribute("class", "hour-close")
                }
              }
            )
          }
        }
      )
    } else {
      target.childNodes[2].style.transform = "rotate(" + 0 + "deg)"
      target.childNodes[2].setAttribute("name", "none")
      target.childNodes[2].parentNode.parentNode.parentNode.childNodes.forEach(
        (node) => {
          if (node.childNodes[1].innerText.split(":")[0] === targetHour) {
            node.childNodes[1].setAttribute("class", "hour-close")
          }
        }
      )
    }
    console.log(inputField)
  }

  /*空き枠数により◯△の表示を分ける。予約を取ることができない時間帯は表示させない
   */
  const renderIntraDayHours = () => {
    let tmpArr: JSX.Element[] = []
    for (let i = 0; i < 13; i++) {
      let conditionDiv: JSX.Element
      let condition = false
      if (countEachHours[i] > 4) {
        conditionDiv = <div className="space-condition-good"></div>
        condition = true
      } else if (countEachHours[i] > 0) {
        conditionDiv = <div className="space-condition-few"></div>
        condition = true
      } else {
        conditionDiv = <></>
      }
      let minutes: SchedulableTime[] = []
      arr.map((elem) => {
        if (elem.startAt.getHours() - 9 === i) {
          minutes.push(elem)
        }
      })
      if (condition) {
        tmpArr.push(
          <div key={`${currentDay}-${i}`} className="hours-wrapper">
            <section className="hours" onClick={handleHourClick}>
              {conditionDiv}
              <div className="hour">{i + 9 + ":00~"}</div>
              <button name="none" ref={(elem) => (inputField = elem)}></button>
            </section>
            <IntraDayMinutes
              key={i}
              times={minutes}
              currentDay={
                new Date(
                  currentDay.getFullYear(),
                  currentDay.getMonth(),
                  currentDay.getDate(),
                  i + 9
                )
              }
              onSelectTime={props.onSelectTime}
            ></IntraDayMinutes>
          </div>
        )
      }
    }

    return (
      <div className="wrapper">
        <ul id="intra-day-hours">{tmpArr.map((row, i) => tmpArr[i])}</ul>
      </div>
    )
  }

  return renderIntraDayHours()
}

export default IntraDayHours
