import React, { ReactElement, useState } from "react"

import { Alert } from "reactstrap"

import { InterviewItem, InputInterviewAnswer } from "./types"
import ImageUploadSection from "./image_upload_section"
import FormInputElement from "./FormInputElement"
import images from "../../helpers/ui-helpers/ImageSources"
import { Card, CardBody } from "reactstrap"

declare global {
  interface Window {
    dataLayer: any
  }
}

type Props = {
  errorMessage: String | null
  buttonText?: string
  interviewItems: Array<InterviewItem>
  interviewChildItems: Array<InterviewItem>
  inputInterviewAnswers: Array<InputInterviewAnswer>
  TopDescription?: ReactElement
  imageUploadPath: string
  isShowHeadImageInput: Boolean
  isHideAlert: boolean
  setInputInterviewAnswers: (value) => void
  setInterviewItems: (value) => void
  setAddressInfo?: (value) => void
  setErrorMessage: (value: string | null) => void
  onSubmit: () => void
  customerId?: string | null
  canAnswer: boolean
}

const IndexPresentation: React.FC<Props> = (props) => {
  const [ageValue, setAgeValue] = useState<number | undefined>()

  const handleChange = (id, value) => {
    const target = props.inputInterviewAnswers.find((info) => info.id === id)
    let interviewItem = props.interviewItems.find((item) => item.id === id)

    if (interviewItem?.question_type === "address") {
      if (props.setAddressInfo) props.setAddressInfo(value)

      return
    }

    if (interviewItem?.need_aditional_question_list) {
      handleChildItems(interviewItem, value)
    }

    upsertInfo(id, value, target, interviewItem)

    // 誕生日を変更したときには年齢の欄にも数値を入力する
    if (interviewItem?.question_type === "birthday") {
      const ageParentItem = props.interviewItems.find(
        (item) => item.question_type === "age"
      )
      const ageChildrenItem = props.interviewChildItems.find(
        (item) => item.question_type === "age"
      )

      const ageItem = ageParentItem || ageChildrenItem
      if (!ageItem) return

      const ageTarget = props.inputInterviewAnswers.find(
        (info) => info.id === ageItem.id
      )
      const newAge = calcAge(value)

      setAgeValue(newAge)

      upsertInfo(ageItem.id, newAge, ageTarget, ageItem)
    }
  }

  const handleChildItems = (parent, value) => {
    let newItems = props.interviewItems

    const arr = parent.need_aditional_question_list.split(",")
    let isMoveForward: boolean
    if (parent.question_type.match("prescription_month")) {
      // ex) value = '3ヶ月¥2,890'
      // 値段にコンマが含まれていてsplitでおかしくなるので一旦これで対応
      // TODO: FIX
      isMoveForward = arr.some((v) => value.match(v))
    } else if (Array.isArray(value)) {
      isMoveForward = value.some((v) => arr.includes(v))
    } else {
      isMoveForward = arr.includes(value)
    }

    const childItems: InterviewItem[] = props.interviewChildItems.filter(
      (child) => child.parent_uuid === parent.id
    )
    // 一旦除外
    newItems = newItems.filter((item) => !childItems.includes(item))

    if (isMoveForward) {
      const newChildItems = childItems.filter((child) => {
        // correspondAnswersがnullなら全て表示
        if (!child.correspond_answer) return true

        const correspondAnswers = child.correspond_answer?.split(",")
        if (Array.isArray(value)) {
          return value.some((v) => correspondAnswers.includes(v))
        } else {
          return correspondAnswers.includes(value)
        }
      })
      newItems = newItems.concat(newChildItems)
    }

    // 子だけじゃなくその子孫含めて表示/非表示にする
    let targets = childItems
    let safetyCount = 0

    while (targets.length > 0) {
      let nextTargets: InterviewItem[] = []
      // eslint-disable-next-line no-loop-func
      targets.forEach((target) => {
        if (!target.need_aditional_question_list) return

        const value = props.inputInterviewAnswers.find(
          (answer) => (answer.id = target.id)
        )?.value
        if (!value) return

        const arr = target.need_aditional_question_list.split(",")
        let isMoveForward: boolean

        if (Array.isArray(value)) {
          isMoveForward = value.some((v) => arr.includes(v))
        } else {
          isMoveForward = arr.includes(value)
        }
        const childItems: InterviewItem[] = props.interviewChildItems.filter(
          (child) => child.parent_uuid === target.id
        )
        nextTargets = nextTargets.concat(childItems)

        // 一旦除外
        newItems = newItems.filter((item) => !childItems.includes(item))

        if (isMoveForward) {
          const newChildItems = childItems.filter((child) => {
            // correspondAnswersがnullなら全て表示
            if (!child.correspond_answer) return true

            const correspondAnswers = child.correspond_answer?.split(",")
            if (Array.isArray(value)) {
              return value.some((v) => correspondAnswers.includes(v))
            } else {
              return correspondAnswers.includes(value)
            }
          })
          newItems = newItems.concat(newChildItems)
        }
      })

      targets = nextTargets
      safetyCount++
      if (safetyCount > 10) break
    }
    props.setInterviewItems(newItems.sort((a, b) => a.order - b.order))
  }

  const upsertInfo = (id, value, target, intervieItem) => {
    if (target) {
      target.value = value
    } else {
      if (!intervieItem) return

      const newInfo: InputInterviewAnswer = {
        id: id,
        value: value,
        title: intervieItem.title_for_doctor || intervieItem.title,
        order: intervieItem.order,
      }
      const inputInterviewAnswers = props.inputInterviewAnswers
      inputInterviewAnswers.push(newInfo)
      props.setInputInterviewAnswers(inputInterviewAnswers)
    }
  }

  const today = new Date()
  const today_numeric =
    today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate()
  const calcAge = (date) => {
    const birthday = new Date(date)

    const birthday_numeric =
      birthday.getFullYear() * 10000 +
      (birthday.getMonth() + 1) * 100 +
      birthday.getDate()
    const age = Math.floor((today_numeric - birthday_numeric) / 10000)

    return age
  }

  const references = {}
  props.interviewItems.forEach((item) => {
    references[item.id] = React.createRef()
  })

  const emptyRequiredItems = () => {
    const requiredItems = props.interviewItems.filter((item) => item.required)

    const emptyRequiredItems = requiredItems.filter((item) => {
      const input = props.inputInterviewAnswers.find(
        (info) => info.id === item.id
      )

      if (!input) return true
      if (!input.value || input.value === "") return true

      return false
    })

    return emptyRequiredItems
  }

  const submit = () => {
    if (!props.inputInterviewAnswers) {
      return false
    }

    if (emptyRequiredItems().length > 0) {
      const errorMessage = emptyRequiredItems().reduce(
        (previous, current) => previous.concat(`\n・${current.title}`),
        "以下の項目が未入力です"
      )
      props.setErrorMessage(errorMessage)

      const firstRequiredItem = emptyRequiredItems().sort(
        (a, b) => a.order - b.order
      )[0]
      const offsetTop = references[firstRequiredItem.id].current?.offsetTop
      // ヘッダーの高さ = 56px
      window.scrollTo({ top: offsetTop - 56, left: 0, behavior: "smooth" })

      return false
    }
    window.dataLayer.push({
      event: "item_submit",
    })

    props.onSubmit()
  }
  return (
    <>
      <div className="container-nallow">
        <div className="WebInterview pb-5">
          {props.errorMessage && (
            <Alert
              color="warning"
              className="sticky-top w-100"
              style={{
                top: "56px",
                transition: "1s",
                opacity: props.isHideAlert ? 0 : 1,
              }}
            >
              <pre style={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
                {props.errorMessage}
              </pre>
            </Alert>
          )}
          {props.TopDescription ? (
            props.TopDescription
          ) : (
            <Card className="mb-4">
              <CardBody>
                <div className="card-icon">
                  <img src={images.webInterviewIcon} alt="問診票" />
                </div>
                <h3 className="WebInterview__Title mb-3 text-center">
                  事前WEB問診票
                </h3>
                <p className="WebInterview__TextEmphasis text-bg__gray rounded font-weight-medium py-2 mb-3 text-center">
                  所要時間：1~3分
                </p>
                <p className="mb-4">
                  ※WEB問診表が未回答の場合、カウンセリング後の医師との
                  <b>診療を実施できない可能性</b>がございます。
                </p>
              </CardBody>
            </Card>
          )}

          {props.canAnswer &&
            props.interviewItems.map((data) => {
              return (
                <FormInputElement
                  data={data}
                  handleChange={handleChange}
                  inputInterviewAnswers={props.inputInterviewAnswers}
                  key={`item_${data.id}`}
                  ageValue={ageValue}
                  imageUploadPath={props.imageUploadPath}
                  setErrorMessage={props.setErrorMessage}
                  ref={references[data.id]}
                  customerId={props.customerId}
                />
              )
            })}
          {props.isShowHeadImageInput && props.canAnswer && (
            <ImageUploadSection
              imageUploadPath={props.imageUploadPath}
              setErrorMessage={props.setErrorMessage}
            ></ImageUploadSection>
          )}
          {props.canAnswer && (
            <div className="container-center">
              <p className="my-4 pb-1">
                ご回答ありがとうございました。送信ボタンを押してください。
              </p>
              <button
                onClick={() => submit()}
                className="WebInterview__Button mb-5"
              >
                {props.buttonText ? props.buttonText : "問診内容を送信する"}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default IndexPresentation
