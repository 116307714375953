export const ScheduleType = {
  counseling: "counseling",
  counselingByDoctor: "counseling_by_doctor",
  medicalExamination: "medical_examination",
  sideEffectExamination: "side_effect_examination",
  immediateExamination: "immediate_examination",
  UnableToPrescribe: "unable_to_prescribe",
  fci: "fci",
  intermediateExamination: "intermediate_examination",
} as const

export type ScheduleType = typeof ScheduleType[keyof typeof ScheduleType]

export type SchedulableTime = {
  startAt: Date
  endAt: Date
  isSchedulable: boolean
}

export type SchedulableDate = {
  date: string
  times: SchedulableTime[]
}

export type SchedulableFlag = {
  date: Date
  flag: boolean
}

export type currentDateType = {
  currentDay: Date
}

export type Customer = {
  id: string
  name: string
  course: "counseling" | "counseling_by_doctor" | "immediate" | "FCI" | ""
  email: string
  phone: string
}
